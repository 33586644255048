<template>
  <div style="background-color: #FFFFFF;position: relative;">
    <div style="position: absolute;right:20px;z-index: 10;top: 15px;">
      <span
        style="color: #5D6FE9; font-size: 14px; cursor: pointer;"
        @click="handleAllRead">全部已读
      </span>
    </div>
    <el-tabs>
      <el-tab-pane label="消息中心">
        <!-- <el-row>
          <el-col :span="24">
            <div class="mc-toolbar">
              <el-badge :value="systemPage.total" style="margin: auto 20px;"><el-button :type="messageType === 'system' ? 'primary' : ''" @click="messageType='system'" >系统消息</el-button></el-badge>
              <el-badge :value="orderPage.total" style="margin: auto 20px;"><el-button :type="messageType === 'order' ? 'primary' : ''" @click="messageType='order'">订单消息</el-button></el-badge>
              <el-badge :value="noticePage.total" style="margin: auto 20px;"><el-button :type="messageType === 'notice' ? 'primary' : ''" @click="messageType='notice'">订单通知</el-button></el-badge>
              <el-badge :value="maintainPage.total" style="margin: auto 20px;"><el-button :type="messageType === 'maintain' ? 'primary' : ''" @click="messageType='maintain'">维护消息</el-button></el-badge>
            </div>
          </el-col>
        </el-row> -->
        <el-row>
          <el-col :span="24">
            <div v-if="messageType === 'system'">
              <div class="mc-list">
                <el-table :data="systemDataList" border :header-cell-style="headerColor">
                  <el-table-column type="index" label="序号" width="80" align="center">
                  </el-table-column>
                  <el-table-column prop="noticeContent" label="标题" align="center">
                    <template #default="scope">
                     <span v-if="scope.row.readStatus == '0'" class="link-hover"  @click="handleReadMes(scope.row)">
                       {{ scope.row.noticeContent }}
                        <el-badge is-dot class="item" type="error" v-if="scope.row.readStatus == '0'"></el-badge>
                     </span>
                     <span v-else-if="scope.row.extraType == '76'" class="link-hover" @click="openReport(scope.row)">
                       {{ scope.row.noticeContent }}
                        <el-badge is-dot class="item" type="error" v-if="scope.row.readStatus == '0'"></el-badge>
                     </span>
                     <span v-else-if="scope.row.extraType == '60'" class="link-hover" @click="handleToMain()">
                       {{ scope.row.noticeContent }}
                        <el-badge is-dot class="item" type="error"  v-if="scope.row.readStatus == '0'"></el-badge>
                     </span>
                     <span v-else>
                       {{ scope.row.noticeContent }}
                        <el-badge is-dot class="item" type="error" v-if="scope.row.readStatus == '0'"></el-badge>
                     </span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="createDate" label="时间" width="160" align="center">
                  </el-table-column>
                  <template #empty style="width: 100%;">
                    <el-empty :image-size="200"></el-empty>
                  </template>
                </el-table>
              </div>
              <div class="mc-page">
                <el-pagination
                  :hide-on-single-page="true"
                  :pager-count="systemPage.pagerCount"
                  background
                  layout="prev, pager, next"
                  @current-change="currentChangeSystem"
                  :total="systemPage.total">
                </el-pagination>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import { forwardBID } from '@/api/forward.js'
import { mapGetters } from 'vuex'
import { getDownLoadUrlByReportIdOpen } from '@/api/user.js'

export default {
  name: 'message-center',
  data () {
    return {
      systemDataList: null,
      systemPage: {
        pagerCount: 7,
        total: 0,
        currentPage: 1
      },
      orderDataList: null,
      orderPage: {
        pagerCount: 7,
        total: 0,
        currentPage: 1
      },
      noticeDataList: null,
      noticePage: {
        pagerCount: 7,
        total: 0,
        currentPage: 1
      },
      maintainDataList: null,
      maintainPage: {
        pagerCount: 7,
        total: 0,
        currentPage: 1
      },
      messageType: 'system'
    }
  },
  mounted () {
    this.loadDataSystem()
  },
  computed: {
    ...mapGetters([
      'vipData', // 会员数据
      'idToken',
      'userInfo'
    ])
  },
  methods: {
    headerColor: function ({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      return 'background-color:#E8EBFC;color:#666666;font-size:14px;text-align:center'
    },
    handleAllRead () {
      const systemData = {
        dateBackgroundUrl: '/bid/noticeUser/readAllNoticeUser',
        dateBackgroundRequestType: 'post',
        data: {
          idToken: this.idToken
        }
      }
      forwardBID(systemData)
        .then(result => {
          if (result.code === 0) {
            ElMessage.success({ message: '已全部设置已读！', offset: 60 })
            this.loadDataSystem()
            this.getMessage()
          } else {
            ElMessage.error({ message: result.msg, offset: 60 })
          }
        })
        .catch(error => {
          ElMessage.error({ message: error, offset: 60 })
        })
    },
    loadDataSystem () {
      const systemData = {
        dateBackgroundUrl: '/bid/noticeUser/getNoticeUserListToXinYiFu',
        dateBackgroundRequestType: 'get',
        data: {
          idToken: this.idToken,
          page: this.systemPage.currentPage,
          rows: this.systemPage.pagerCount
        }
      }
      forwardBID(systemData)
        .then(result => {
          console.log(result)
          if (result.code === 0) {
            // this.systemDataList = result.data.list
            if (result.data.list.length > 0) {
              const arr = []
              result.data.list.forEach(item => {
                if (item.extraType === '76') {
                  const arrs = item.noticeContent.split('$')
                  if (arrs.length > 1) {
                    item.noticeContent = arrs[0]
                    item.blCreditReportId = arrs[1]
                  }
                }
                arr.push(item)
              })
              this.systemDataList = arr
            }
            this.systemPage.total = result.data.total
          } else {
            ElMessage.error({ message: result.msg, offset: 60 })
          }
        })
        .catch(error => {
          ElMessage.error({ message: error, offset: 60 })
        })
    },
    currentChangeSystem (currentPage) {
      this.systemPage.currentPage = currentPage
      this.loadDataSystem()
    },
    getMessage () {
      const data = {
        dateBackgroundUrl: '/bid/noticeUser/getNoticeUserNumToXinYiFu',
        dateBackgroundRequestType: 'get'
      }
      forwardBID(data).then(result => {
        if (result.code === 0) {
          this.$store.dispatch('setMesCont', result.data)
        }
      })
    },
    handleToReport () {
      this.$router.push({
        name: 'report'
      })
    },
    openReport (item) {
      if (item?.blCreditReportId) {
        const data = {
          reportId: item.blCreditReportId
        }
        getDownLoadUrlByReportIdOpen(data).then(result => {
          if (result.code === 0) {
            const res = result.data
            window.open(res + '&requestType=1', '_blank')
          }
        })
      } else {
        this.$router.push({
          name: 'report'
        })
      }
    },
    handleToMain () {
      this.$router.push({
        name: 'data-maintain-person'
      })
    },
    handleReadMes (item) {
      const data = {
        dateBackgroundUrl: '/bid/puchaserAccountInfo/readMessage',
        dateBackgroundRequestType: 'post',
        data: {
          id: item.id
        }
      }
      forwardBID(data).then(result => {
        if (result.code === 0) {
          this.loadDataSystem()
          this.getMessage()
          if (item.extraType === '76') {
            this.openReport(item)
          }
          if (item.extraType === '60') {
            this.handleToMain()
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.mc-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto 20px;
  height: 55px;
}

/deep/ .el-tabs__item {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  width: 100px;
}

.pc-title {
  font-size: 16px;
  color: #333333;
}

.mc-toolbar {
  margin: 20px;
  display: flex;
  justify-content: flex-start;
}

.mc-list {
  margin: 20px;
}

/*.mc-toolbar {*/
/*  position: absolute;*/
/*  right:20px;*/
/*  z-index: 10;*/
/*}*/

.mc-page {
  display: flex;
  justify-content: flex-end;
  margin: 20px auto;
}
/deep/ .el-tabs__item{
  height: 55px;
  line-height: 55px;
}
.link-hover:hover {
  font-weight: bold;
  cursor: pointer;
}
</style>
